import FirstScreenFront from "~/components/Enga/Components/FirstScreenFront";
import imgProxy from "~/utils/imgproxy";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { urlRs } from "~/utils/url";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";

import "./EngaPages.css";
import "./Enga0Page.css";

import IconPlayArrow from "~/img/icons/play_arrow.svg";
import IconArrowForward from "~/img/icons/arrow_forward.svg";

export default function Enga0Page() {
  return (
    <>
      <div class="page-engagement-front">
        <FirstScreenFront />
        <Enga0Manifeste />
        <Enga0EngaList />
      </div>

      <AutopromoContainer />
    </>
  );
}

function Enga0Manifeste() {
  return (
    <>
      <section class="manifeste">
        <div class="content-part">
          <p>
            Un logement de qualité, ça n’a peut-être l’air de rien, mais ça peut
            faire beaucoup de bien.
          </p>
          <p>
            <strong>Du bien à ceux qui y vivent</strong>, en leur proposant un
            cadre de vie sain et source de bien-être.
          </p>
          <p>
            <strong>Du bien à la ville</strong>, en développant des projets qui
            s’intègrent harmonieusement à l'espace urbain.
          </p>
          <p>
            <strong>Du bien à la planète</strong>, en construisant des bâtiments
            plus écologiques, dans le respect de l’environnement.
          </p>
          <p>
            Construire des biens qui font du bien, c’est concevoir des
            appartements qui pensent à demain et prennent soin de chacun.
          </p>
          <p class="spacer">
            Cogedim, <strong>Des biens qui font du bien.</strong>
          </p>
        </div>
      </section>
    </>
  );
}

function Enga0EngaList() {
  return (
    <>
      <section class="engagements-list">
        <div class="introduction">
          <p>
            Construire des biens qui font du bien, c’est s’engager à mettre tout
            en oeuvre pour faire de nos appartements une source de bien-être,
            avec un impact positif sur notre santé et sur l’environnement.
          </p>
          <h3>Découvrez nos engagements</h3>
        </div>

        <div class="engagements-blocks">
          <Enga1Block />
          <Enga2Block />
          {/* <Enga3Block /> Hide from ref #8252 */}
          <div class="row-engagements">
            <Enga4Block />
            <Enga5Block />
          </div>
          <div class="row-engagements">
            <Enga6Block />
            <Enga7Block />
          </div>
          <Enga8Block />
          <div class="row-engagements">
            <Enga9Block />
            <Enga10Block />
          </div>
        </div>
      </section>
    </>
  );
}

function Enga1Block() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <article class="engagement-block double visual-left" id="engagement-1">
        <div class="visual-part">
          <picture class="visual">
            <source
              media="(min-width: 375px)"
              srcset={imgProxy(
                "/images/engagements/page-engagement-1-visual-mobile.jpg",
                "size:960:1080/resizing_type:fill",
              )}
            />
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-1-visual-mobile.jpg",
                `size:375:422/resizing_type:fill`,
              )}
              alt=""
              height="422"
              width="375"
              loading="lazy"
            />
          </picture>
          <h3>
            <span>Voici un esprit sain</span> <span>dans un corps sain</span>{" "}
            <span>dans un intérieur sain</span>
          </h3>
        </div>
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour votre santé</span>
          </div>
          <h2>Une meilleure qualité de l’air</h2>
          <p class="section">
            Construire des biens qui font du bien, c’est notamment intégrer dans
            100% de nos logements des{" "}
            <strong>
              systèmes de ventilation renforcée et des peintures labellisées
            </strong>{" "}
            qui limitent les émissions dans le respect des normes NF Habitat.
          </p>
          <p class="blockquote-introduction">
            Suzanne Déoux, Docteur en médecine ORL, Présidente de Bâtiment Santé
            Plus, Fondatrice de MEDIECO Conseil & Formation, nous explique l’
            importance du renouvellement de l’air de nos appartements.
          </p>
          <blockquote>
            <div class="visual">
              <img
                src={imgProxy(
                  "/images/engagements/engagement-1-blockquote.jpg",
                  `size:375:400/resizing_type:fill`,
                )}
                alt=""
                height="400"
                width="375"
              />
              <button
                class="btn btn-icon"
                onClick={() => {
                  setMediaOverlay(
                    "embed",
                    `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/tQGsKnotBC8" allowfullscreen />`,
                  );
                }}
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconPlayArrow />
                </i>
              </button>
            </div>
            <p>
              Nous inhalons chaque jour 12m<sup>3</sup> d’air, dans les
              logements nous y passons 80 à 90% du temps.
            </p>
          </blockquote>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/une-meilleure-qualite-de-l-air.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> qualité de l’air
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

function Enga2Block() {
  return (
    <>
      <article class="engagement-block double visual-right">
        <div class="visual-part">
          <picture class="visual">
            <source
              media="(min-width: 375px)"
              srcset={imgProxy(
                "/images/engagements/page-engagement-2-visual-mobile.jpg",
                "size:960:1080/resizing_type:fill",
              )}
            />
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-2-visual-mobile.jpg",
                `size:375:422/resizing_type:fill`,
              )}
              alt=""
              height="422"
              width="375"
              loading="lazy"
            />
          </picture>
          <h3>
            <span>On prévoit</span> <span>un temps doux</span>{" "}
            <span>du nord de la salle</span> <span>de bain jusqu’au sud</span>{" "}
            <span>du salon.</span>
          </h3>
        </div>
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <h2>Le confort d’été</h2>
          <p class="section">
            Nos logements doivent progressivement s’adapter au réchauffement
            climatique.
          </p>
          <p class="illustration-introduction">
            Tous nos nouveaux projets de logement bénéficient d’une conception
            optimisée pour limiter la sensation de chaleur l’été sans
            climatisation.
          </p>
          <div class="small-excerpt">
            <img
              src="/images/engagements/engagement-2-illustration.png"
              alt=""
              height="127"
              width="175"
              loading="lazy"
            />
            <p>
              Nos équipes intègrent dès la conception des opérations des
              solutions techniques et architecturales passives permettant
              d’améliorer le confort des logements comme des espaces extérieurs.
            </p>
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-confort-d-ete.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> pour le confort
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

/*
function Enga3Block() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <article class="engagement-block double visual-left">
        <div class="visual-part">
          <picture class="visual">
            <source
              media="(min-width: 375px)"
              srcset={imgProxy(
                "/images/engagements/page-engagement-3-visual-mobile.jpg",
                "size:960:1080/resizing_type:fill",
              )}
            />
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-3-visual-mobile.jpg",
                `size:375:422/resizing_type:fill`,
              )}
              alt=""
              height="422"
              width="375"
              loading="lazy"
            />
          </picture>
          <h3>
            <span>Notre conseil santé&nbsp;:</span>{" "}
            <span>appartement lumineux,</span> <span>matin, midi et soir.</span>
          </h3>
        </div>
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour votre bien être</span> <span>Pour votre santé</span>
          </div>
          <h2>Plus de lumière, moins de bruit</h2>
          <p class="section">
            Construire des biens qui font du bien, c’est vous garantir des
            appartements avec des{" "}
            <strong>
              surfaces vitrées supérieures au standard de 20% et un confort
              acoustique renforcé
            </strong>
            .
          </p>
          <p class="blockquote-introduction">
            La qualité de l’environnement sonore expliquée par Dominique Bidou,
            Président d’Honneur de l'association HQE - GBC.
          </p>
          <blockquote>
            <div class="visual">
              <img
                src="/images/engagements/engagement-3-blockquote.jpg"
                alt=""
              />
              <button
                class="btn btn-icon"
                onClick={() => {
                  setMediaOverlay(
                    "embed",
                    `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/4UBECUaGS5o" allowfullscreen />`,
                  );
                }}
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconPlayArrow />
                </i>
              </button>
            </div>
            <p>
              Au-delà même du silence, la qualité de l’environnement sonore est
              quelque chose de très important : le bruit fait partie de la vie,
              il ne faudrait pas le supprimer.
            </p>
          </blockquote>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/plus-de-lumiere-moins-de-bruit.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> luminosité et calme
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}
*/

function Enga4Block() {
  return (
    <>
      <article class="engagement-block cyan-bg">
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <h2>Des aménagements intelligents</h2>
          <div class="excerpt">
            <p>
              <span>On a complètement</span> <span>repensé nos coins</span>{" "}
              <span>bureaux. Et voilà le</span> <span>télétravail !</span>
            </p>
            <img
              src="/images/engagements/engagement-4-excerpt.jpg"
              alt=""
              height="240"
              width="380"
              loading="lazy"
            />
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-amenagements-intelligents.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> aménagement
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

function Enga5Block() {
  return (
    <>
      <article class="engagement-block violet-bg">
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <h2>100% d'espaces extérieurs</h2>
          <div class="excerpt">
            <p>
              <span>Et si votre</span> <span>pièce préférée</span>{" "}
              <span>n’avait ni murs</span> <span>ni plafond&nbsp;?</span>
            </p>
            <img
              src="/images/engagements/engagement-5-excerpt.jpg"
              alt=""
              height="240"
              width="380"
              loading="lazy"
            />
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-espaces-exterieurs-vegetalises.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> espaces extérieurs
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

function Enga6Block() {
  return (
    <>
      <article class="engagement-block violet-bg">
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour le respect de l’environnement</span>
          </div>
          <h2>Mobilité</h2>
          <div class="excerpt">
            <p>
              <span>Chez nous,</span> <span>les vélos</span>{" "}
              <span>se sentent</span> <span>comme</span> <span>chez eux.</span>
            </p>
          </div>
          <div class="small-excerpt">
            <img
              src="/images/engagements/engagement-6-small-excerpt.png"
              alt=""
              height="110"
              width="148"
              loading="lazy"
            />
            <p>
              Construire des biens qui font du bien, c’est{" "}
              <strong>favoriser les mobilités douces</strong> en intégrant dans
              toutes nos résidences des locaux vélo adaptés et sécurisés ainsi
              que des stations de gonflage et de réparation.
            </p>
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/mobilite.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> mobilité
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

function Enga7Block() {
  return (
    <>
      <article class="engagement-block visual">
        <div class="visual-overlay">
          <picture class="visual">
            <source
              media="(min-width: 375px)"
              srcset={imgProxy(
                "/images/engagements/page-engagement-7-visual-accueil.jpg",
                "size:960:1080/resizing_type:fill",
              )}
            />
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-7-visual-accueil.jpg",
                `size:375:422/resizing_type:fill`,
              )}
              alt=""
              height="422"
              width="375"
              loading="lazy"
            />
          </picture>
        </div>
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour le respect de l’environnement</span>
          </div>
          <h2>Le respect de la biodiversité</h2>
          <div class="excerpt">
            <p>
              <span>Nos paysagistes ont</span> <span>travaillé main dans</span>{" "}
              <span>la main avec la</span> <span>nature.</span>
            </p>
          </div>
          <div class="small-excerpt">
            <img
              src="/images/engagements/engagement-7-small-excerpt.png"
              alt=""
              height="80"
              width="80"
              loading="lazy"
            />
            <p>
              À Vitrolles, la réserve des Salins du Lion, en bordure de l’étang
              de Berre, est réputée par sa richesse ornithologique.
            </p>
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-respect-de-la-biodiversite.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> biodiversité
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

function Enga8Block() {
  return (
    <>
      <article class="engagement-block double visual-left">
        <div class="visual-part">
          <picture class="visual">
            <source
              media="(min-width: 375px)"
              srcset={imgProxy(
                "/images/engagements/page-engagement-8-visual-mobile.jpg",
                "size:960:1080/resizing_type:fill",
              )}
            />
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-8-visual-mobile.jpg",
                `size:375:422/resizing_type:fill`,
              )}
              alt=""
              height="422"
              width="375"
              loading="lazy"
            />
          </picture>
          <h3>
            <span>Construire</span> <span>sans détruire, c’est</span>{" "}
            <span>pour aujourd’hui</span> <span>ou pour demain&nbsp;?</span>{" "}
            <span>Les deux.</span>
          </h3>
        </div>
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <h2>Qualité et pérennité des constructions</h2>
          <p class="blockquote-introduction">
            Construire des biens qui font du bien, c’est vous proposer des
            projets certifiés NF Habitat, conçus avec des matériaux durables et
            respectueux de l’environnement.
          </p>
          <div class="small-excerpt">
            <img
              src="/images/engagements/engagement-8-small-excerpt.png"
              alt=""
              height="80"
              width="80"
              loading="lazy"
            />
            <p>
              Cogedim est un des rares promoteurs nationaux agréé NF Habitat,
              HQE maturité 3, assurant notamment des matériaux durables.
            </p>
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/qualite-et-perennite-des-constructions.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> pérennité
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

function Enga9Block() {
  return (
    <>
      <article class="engagement-block visual">
        <div class="visual-overlay">
          <picture class="visual">
            <source
              media="(min-width: 375px)"
              srcset={imgProxy(
                "/images/engagements/page-engagement-9-visual-mobile.jpg",
                "size:960:1080/resizing_type:fill",
              )}
            />
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-9-visual-mobile.jpg",
                `size:375:422/resizing_type:fill`,
              )}
              alt=""
              height="422"
              width="375"
              loading="lazy"
            />
          </picture>
        </div>
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour le respect de l’environnement</span>
          </div>
          <h2>Des bâtiments plus sobres</h2>
          <div class="excerpt">
            <p>
              <span>Nous faisons</span> <span>pousser nos</span>{" "}
              <span>immeubles dans</span> <span>le respect de</span>{" "}
              <span>l’environnement.</span>
            </p>
          </div>
          <div class="small-excerpt">
            <img
              src="/images/engagements/engagement-9-small-excerpt.png"
              alt=""
              height="148"
              width="148"
              loading="lazy"
            />
            <p>
              Construire des biens qui font du bien, c’est{" "}
              <strong>
                réduire les émissions de CO<sub>2</sub>
              </strong>{" "}
              pendant la phase de construction et proposer{" "}
              <strong>des bâtiments plus économes en énergie</strong>.
            </p>
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-batiments-plus-respectueux-de-l-environnement.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> environnement
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}

function Enga10Block() {
  return (
    <>
      <article class="engagement-block cyan-bg">
        <div class="text-part">
          <span class="number">Cogedim s’engage</span>
          <div class="tags">
            <span>Pour le respect de l’environnement</span>
          </div>
          <h2>Économiser l’eau</h2>
          <div class="excerpt">
            <p>
              <span>Économiser l’eau</span> <span>ça coule de source.</span>
            </p>
          </div>
          <div class="small-excerpt">
            <img
              src="/images/engagements/engagement-10-small-excerpt.png"
              alt=""
              height="148"
              width="132"
              loading="lazy"
            />
            <p>
              Construire des biens qui font du bien, c’est faire en sorte que
              toutes nos douches et WC soient équipés de systèmes pour réduire
              votre consommation d'eau.
            </p>
          </div>
          <a
            class="btn engagement-link"
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/economiser-l-eau.html",
            )}
          >
            <span>
              <span class="desktop">Nos engagements</span> économie d’eau
            </span>{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </div>
      </article>
    </>
  );
}
